<template>
  <div class="container mx-auto my-8 relative">
    <hr class="border-t-4 border-gray-900" />
    <h1 class="my-4">Download</h1>
    <article>
      <p>If you have purchased a <b>Listen Closely CD</b> and have a download code, please enter it below to get your download link!</p>
      <div class="my-3">
        <input v-model="code" @input="onCodeInput" :disabled="inputDisabled" :class="inputClasses" class="appearance-none bg-white border-4 border-gray-900 font-mono text-xl w-40 text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Code" aria-label="Code">
        <p class="text-xs">The code is <b>case sensitive!</b></p>
      </div>
      <p v-if="errorMessage" class="text-red-600 text-bold">{{ errorMessage }}</p>
      <p v-if="downloadLink">Please click <a :href="downloadLink" target="_blank"><b>here</b></a> to download the album.</p>
    </article>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data: function() {
    return {
      code: '',
      inputDisabled: false,
      codeSending: false,
      errorMessage: '',
      downloadLink: ''
    }
  },
  computed: {
    inputClasses: function() {
      return {
        'bg-gray-400': this.inputDisabled,
        'opacity-50': this.inputDisabled,
        'animate-pulse': this.codeSending
      }
    }
  },
  methods: {
    onCodeInput: function() {
      if (this.code.length == 10) {
        this.inputDisabled = true;
        this.sendCode(this.code);
      }
    },
    sendCode: async function(code) {
      this.codeSending = true;
      let response;
      try {
        response = await axios.post('/download', { code });
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.inputDisabled = false;
          this.errorMessage = 'Invalid code. Please try to type it again carefully.'
        } else if (error.response && error.response.status === 420) {
          this.inputDisabled = false;
          this.errorMessage = 'Calm down, you\'re trying too hard.';
        } else {
          this.errorMessage = 'Unknown error. Reload the page and try again. If it doesn\'t work please contact iben@skizoidglam.net';
        }
        this.codeSending = false;
        return;
      }
      this.codeSending = false;
      this.errorMessage = '';
      
      this.downloadLink = response.data.url;
    }
  }
}

</script>


<style lang="scss" scoped>

</style>
