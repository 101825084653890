<template>
  <div id="app">
    <Intro />
    <Video />
    <Bio />
    <Music />
    <Download/>
    <Footer />
  </div>
</template>

<script>
import Intro from './components/Intro';
import Video from './components/Video';
import Bio from './components/Bio';
import Music from './components/Music';
import Download from './components/Download';
import Footer from './components/Footer';

export default {
  name: 'app',
  components: {
    Intro,
    Video,
    Bio,
    Music,
    Download,
    Footer
  }
};
</script>

<style lang="scss">
#app {
  font-family: 'Saria', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  b {
    font-weight: 500;
  }
  h1,
  h2,
  h3 {
    font-family: 'Anton', sans-serif;
    line-height: 1;
    text-transform: uppercase;
  }
  h1 {
    font-size: 4rem;
  }
}
</style>
